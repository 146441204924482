import React from "react";

class PageNotFound extends React.Component {

    render() {
        return (
            <div className="h-screen flex justify-center"><div className="pt-32">ページが見つかりませんでした。</div></div>
        )
    }

}

export default PageNotFound;