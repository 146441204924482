// import css
import '../styles/global.scss';
import React from 'react';
import { createRoot } from 'react-dom/client';
import TaichiHeader from './components/header';
import TaichiFooter from './components/footer';
const NavigationOverlay = React.lazy(() => import('./components/navigation-overlay'))
import Fade from './components/fade';
import CommonApp from './_commonApp';
import PageNotFound from './components/page-not-found';
import FloatNavigation from './components/float-navigation';

class App extends CommonApp {

    constructor(props) {
        super(props);
        this.closeNav = this.closeNav.bind(this)
        this.openNav = this.openNav.bind(this)
        this.state = {
            openOverlay: false
        }
    }

    componentDidMount() {
        this.checkAvailableImageFormat();
    }

    render() {
        return (
            <div id='wrapper' className='font-default-sans pt-[6rem] lg:pt-0'>
                <TaichiHeader openNav={this.openNav}></TaichiHeader>
                <PageNotFound></PageNotFound>
                <TaichiFooter fixed></TaichiFooter>
                <Fade openOverlay={this.state.openOverlay} exClass='fixed w-screen h-screen top-0 lg:hidden left-full z-50'>
                    <NavigationOverlay closeNav={this.closeNav}></NavigationOverlay>
                </Fade>
                <FloatNavigation></FloatNavigation>
            </div>
        );
    }   

};

const root = createRoot(document.getElementById('app'));
root.render(<App />)